import React, { useState, useRef, useEffect } from 'react';
import {
    Input,
    AutoComplete,
    Layout,
    Divider,
    Result,
    Spin,
    notification,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompaniesWithImageApi } from '../../../api/company';
import HeaderTop from '../../../components/General/HeaderTop';
import ListRestaurant from '../../../components/Client/ListRestaurant';

import './SearchRestaurants.scss';

export default function SearchRestaurants({ history }) {
    const isMounted = useRef(true);
    const [restaurants, setRestaurants] = useState([]);
    const [restaurantsFilter, setRestaurantsFilter] = useState([]);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const { Content } = Layout;

    async function getRestaurantAndOrder() {
        const token = getAccessTokenApi();
        const filter = `isTestingCompany=${true}&isShownInPool=${true}&isActive=${true}`;
        const restaurantsApi = await getCompaniesWithImageApi(token, filter);
        const arrayRest = [
            'DemoCompany',
            'DemoEnvago',
            'DemoRest',
            'DemoTienda',
            'DemoTienda1',
        ];
        const poolRests = restaurantsApi.filter(
            (ele) => !arrayRest.includes(ele.companyId)
        );
        setLoading(true);

        if (isMounted.current) {
            if (restaurantsApi) {
                if (restaurantsApi.statusCode) {
                    notification['error']({
                        message:
                            'Ocurrio un error al cargar los restaurantes, intentelo mas tarde.',
                    });
                } else {
                    setRestaurants(poolRests);
                    // setRestaurantsFilter(restaurantsApi);
                    setLoading(false);
                }
            }
        }
    }

    useEffect(() => {
        getRestaurantAndOrder();
    }, []);

    function filtrarPorID(obj) {
        if (obj.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
            return true;
        } else {
            return false;
        }
    }

    const onSearch = (searchText) => {
        setLoading(true);
        // console.log(searchText);
        if (searchText === '') {
            setRestaurantsFilter(restaurants);
            setQuery('');
        } else {
            setQuery(searchText);
            // console.log(restaurants.filter(filtrarPorID));
            const restaurantFilter = restaurants.filter(filtrarPorID);
            setRestaurantsFilter(restaurantFilter);
            setLoading(false);
        }
        // setOptions(!searchText ? [] : [...options, mockVal(searchText)]);
    };

    const onSelect = (data) => {
        console.log('onSelect', data);
    };

    return (
        <Layout className='perfil'>
            <Content>
                <HeaderTop
                    title={'Restaurantes'}
                    backIcon={true}
                    backUrl={'/'}
                />
                <div className='search-restaurants'>
                    <div className='search-restaurants__autocomplete'>
                        <AutoComplete
                            // allowClear
                            // options={restaurantsName}
                            style={{ width: '100%' }}
                            onSelect={onSelect}
                            onSearch={onSearch}
                            backfill
                            open
                            // filterOption={(inputValue, option) =>
                            //     option.value
                            //         .toUpperCase()
                            //         .indexOf(inputValue.toUpperCase()) !== -1
                            // }
                        >
                            <Input
                                size='large'
                                prefix={
                                    <SearchOutlined
                                        style={{
                                            fontSize: '20px',
                                            color: '#bfbfbf',
                                        }}
                                    />
                                }
                                placeholder={'Buscar restaurante...'}
                            />
                        </AutoComplete>
                    </div>

                    <Divider />

                    {restaurantsFilter.length > 0 && !loading && (
                        <ListRestaurant
                            restaurants={restaurantsFilter}
                            loadingPage={loading}
                            history={history}
                        />
                    )}

                    {restaurantsFilter.length === 0 &&
                        query !== '' &&
                        !loading && (
                            <Result
                                status='404'
                                title='No se encontraron restaurantes'
                                subTitle='Intenta con un termino de búsqueda diferente.'
                            />
                        )}

                    {loading && query !== '' && <Spin size='large' />}
                </div>
            </Content>
        </Layout>
    );
}
