import React, { useEffect, useState } from 'react';
import { Form, Button, Select, notification, Spin, DatePicker } from 'antd';
import moment from 'moment';
import { getAccessTokenApi } from '../../../../api/auth';
import { getCompanySuscriptionApi } from '../../../../api/company';
import {
    createSubscriptionRestApi,
    updateSubscriptionRestApi,
} from '../../../../api/superAdmin';
import { messageError } from '../../../../utils/general';

export default function SubscriptionRest(props) {
    const { companyId, setIsVisibleModal, disableSuscriptionType } = props;
    const [loading, setLoading] = useState(true);
    const [isUpdate, setIsUpdate] = useState(true);
    const token = getAccessTokenApi();
    const [form] = Form.useForm();
    const { Option } = Select;

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 },
    };

    useEffect(() => {
        getCompanySuscriptionApi(token, companyId).then((response) => {
            if (response) {
                if (response.statusCode === 200) {
                    if (response.result.suscriptionType === 0) {
                        setIsUpdate(false);
                    }

                    form.setFieldsValue({
                        suscriptionType: response.result.suscriptionType,
                        startDate:
                            moment.unix(response.result.startDate) !== 0
                                ? moment.unix(response.result.startDate)
                                : undefined,
                        endDate:
                            moment.unix(response.result.endDate) !== 0
                                ? moment.unix(response.result.endDate)
                                : undefined,
                    });
                } else {
                    let msg =
                        'Ocurrio un error al cargar la información, intentelo de nuevo.';
                    if (response.statusCode === 404) {
                        msg = response.description;
                        setIsUpdate(false);
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            } else {
                notification['error']({
                    message: messageError(),
                });
            }
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    // function disabledDate(current) {
    //     // Can not select days before today and today
    //     return current < moment().endOf('day');
    //     // return current && current < moment().endOf('day');
    // }

    const onFinish = (values) => {
        if (values.startDate > values.endDate) {
            notification['error']({
                message:
                    'La fecha de inicio no puede ser mayor a la ficha de termino',
            });
            return false;
        }
        setLoading(true);

        if (values.startDate) {
            values.startDate = values.startDate.unix();
        }
        if (values.endDate) {
            values.endDate = values.endDate.unix();
        }
        if (isUpdate) {
            updateSubscriptionRestApi(token, companyId, values).then(
                (response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            notification['success']({
                                message: 'Actualización exitosa.',
                            });
                            setLoading(false);
                            setIsVisibleModal(false);
                        }
                    } else {
                        let msg = messageError();

                        if (
                            response.statusCode === 409 ||
                            response.statusCode === 404
                        ) {
                            msg = response.description;
                        }
                        notification['error']({
                            message: msg,
                        });
                    }
                }
            );
        } else {
            values['companyId'] = companyId;
            createSubscriptionRestApi(token, values).then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        notification['success']({
                            message: 'Se ha registrado exitosamente.',
                        });
                        setLoading(false);
                        setIsVisibleModal(false);
                    }
                } else {
                    let msg = messageError();

                    if (
                        response.statusCode === 409 ||
                        response.statusCode === 404
                    ) {
                        msg = response.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            });
        }
    };

    const updateEndDate = (value) => {
        form.setFieldsValue({
            endDate: moment(value).add(30, 'days'),
        });
    };

    return (
        <Spin spinning={loading} tip='Cargando...'>
            <Form
                {...layout}
                form={form}
                name='control-hooks'
                onFinish={onFinish}
            >
                <Form.Item
                    name='suscriptionType'
                    label='Suscripción'
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder='Seleccione un tipo'
                        disabled={disableSuscriptionType}
                    >
                        <Option value={1}>Básico</Option>
                        <Option value={2}>Plus</Option>
                        <Option value={3}>Premium</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='startDate'
                    label='Fecha de inicio de la suscripción'
                >
                    <DatePicker
                        allowClear={false}
                        // disabledDate={disabledDate}
                        style={{ width: 200 }}
                        format={'DD-MM-YYYY'}
                        onChange={updateEndDate}
                    />
                </Form.Item>

                <Form.Item
                    name='endDate'
                    label='Fecha de termino de la suscripción'
                >
                    <DatePicker
                        allowClear={false}
                        // disabledDate={disabledDate}
                        style={{ width: 200 }}
                        format={'DD-MM-YYYY'}
                    />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type='primary' htmlType='submit' block>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
