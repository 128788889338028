import React, { useEffect, useState } from 'react';
import {
    Table,
    Button,
    Tag,
    Row,
    Col,
    Tooltip,
    Modal as ModalAntd,
    notification,
    Input,
    Space,
    Image,
    Typography,
    Divider,
    Skeleton,
    DatePicker,
} from 'antd';
import {
    FilterOutlined,
    SearchOutlined,
    CheckOutlined,
    EyeOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import Modal from '../../../General/Modal';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import {
    getPaymentsrestaurantsApi,
    getImagePaymentsApi,
    confirmPaymentAdminApi,
} from '../../../../api/superAdmin';
import './TablePayments.scss';
import {
    formatMoney,
    messageError,
    suscriptionType,
} from '../../../../utils/general';
import SubscriptionRest from '../../../Admin/Restaurants/SubscriptionRest/SubscriptionRest';

const { Paragraph, Text } = Typography;

const TablePayments = () => {
    // const isMounted = useRef(true);

    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [reloadPromos, setReloadPromos] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataMenu, setDataMenu] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [dateRecord, setDateRecod] = useState({
        startDate: moment().subtract(7, 'days').unix(),
        endDate: moment().unix(),
    });

    const { RangePicker } = DatePicker;
    const { confirm } = ModalAntd;

    useEffect(() => {
        handleGetPayments();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRecord, reloadPromos]);

    const handleGetPayments = () => {
        const accessToken = getAccessTokenApi();
        const filters = `?startDate=${dateRecord.startDate}&endDate=${dateRecord.endDate}`;
        setLoadingTable(true);
        getPaymentsrestaurantsApi(accessToken, filters).then((response) => {
            if (response) {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                }
                let dataCompleteMenu = [];

                if (response.statusCode === 200) {
                    response.result.forEach((element) => {
                        element['key'] = element.id;

                        element['paymentType'] = getTypePayment(
                            element.payType
                        );
                        element['subscriptionTypeString'] = suscriptionType(
                            element.subscriptionType
                        );
                        dataCompleteMenu.push(element);
                    });
                    setDataMenu(dataCompleteMenu);
                } else {
                    if (response.statusCode === 404) {
                        setDataMenu(dataCompleteMenu);
                        notification['warning']({
                            message: 'No se han encontrado pagos',
                        });
                    } else {
                        notification['error']({
                            message: messageError(),
                        });
                    }
                }
                setReloadPromos(false);
                setLoadingTable(false);
            }
        });
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getTypePayment = (type) => {
        let typeText = '';
        switch (type) {
            case 1:
                typeText = 'Depósito en efectivo en ventanilla';
                break;
            case 2:
                typeText = 'Pago con Tarjeta de Crédito o Débito';
                break;
            case 3:
                typeText = 'Transferencia interbancaria';
                break;

            default:
                return '';
        }

        return typeText;
    };

    const getChargeStatus = (type) => {
        let typeText = '';
        switch (type) {
            case 0:
                typeText = 'Pendiente';
                break;
            case 1:
                typeText = 'Pagada';
                break;
            case 2:
                typeText = 'Fallido';
                break;
            case 3:
                typeText = 'Cancelado';
                break;

            default:
                return '';
        }

        return typeText;
    };

    const columns = [
        {
            title: 'Clave de la compañia',
            dataIndex: 'companyId',
            key: 'companyId',
            sorter: (a, b) => a.name.length - b.name.length,
            sortOrder: sortedInfo.columnKey === 'companyId' && sortedInfo.order,
            ellipsis: false,
            ...getColumnSearchProps('companyId'),
        },
        {
            title: 'Cliente',
            dataIndex: 'userName',
            key: 'userName',
            sorter: (a, b) => a.userName.length - b.userName.length,
            sortOrder: sortedInfo.columnKey === 'userName' && sortedInfo.order,
            ellipsis: false,
            ...getColumnSearchProps('userName'),
        },
        {
            title: 'Email del cliente',
            dataIndex: 'userEmail',
            key: 'userEmail',
            sorter: (a, b) => a.userEmail.length - b.userEmail.length,
            sortOrder: sortedInfo.columnKey === 'userEmail' && sortedInfo.order,
            ellipsis: false,
            ...getColumnSearchProps('userEmail'),
        },
        {
            title: 'Tipo de suscripción',
            dataIndex: 'subscriptionTypeString',
            key: 'subscriptionTypeString',
            filters: [
                { text: 'Básico', value: 'Basic' },
                { text: 'Plus', value: 'Plus' },
                { text: 'Premium', value: 'Premium' },
            ],
            filteredValue: filteredInfo.subscriptionTypeString || null,
            onFilter: (value, record) =>
                record.subscriptionTypeString === value,
            sorter: (a, b) =>
                a.subscriptionTypeString.length -
                b.subscriptionTypeString.length,
            sortOrder:
                sortedInfo.columnKey === 'subscriptionTypeString' &&
                sortedInfo.order,
            ellipsis: false,
            render: (typePromo) => (
                <p>{typePromo === 'Basic' ? 'Básico' : typePromo}</p>
            ),
        },
        {
            title: 'Método de pago',
            dataIndex: 'paymentType',
            key: 'paymentType',
            filters: [
                {
                    text: 'Depósito en efectivo en ventanilla',
                    value: 'Depósito en efectivo en ventanilla',
                },
                {
                    text: 'Pago con Tarjeta de Crédito o Débito',
                    value: 'Pago con Tarjeta de Crédito o Débito',
                },
                {
                    text: 'Transferencia interbancaria',
                    value: 'Transferencia interbancaria',
                },
            ],
            filteredValue: filteredInfo.paymentType || null,
            onFilter: (value, record) => record.paymentType === value,
            sorter: (a, b) => a.paymentType.length - b.paymentType.length,
            sortOrder:
                sortedInfo.columnKey === 'paymentType' && sortedInfo.order,
            ellipsis: false,
            render: (paymentType) => <p>{paymentType}</p>,
        },
        {
            title: 'Estatus del pago',
            dataIndex: 'chargeStatus',
            key: 'chargeStatus',
            filters: [
                { text: 'Pendiente', value: 0 },
                { text: 'Pagada', value: 1 },
                { text: 'Fallido', value: 2 },
                { text: 'Cancelado', value: 3 },
            ],
            filteredValue: filteredInfo.chargeStatus || null,
            onFilter: (value, record) => record.chargeStatus === value,
            sorter: (a, b) => a.chargeStatus.length - b.chargeStatus.length,
            sortOrder:
                sortedInfo.columnKey === 'chargeStatus' && sortedInfo.order,
            ellipsis: false,
            align: 'center',
            render: (chargeStatus) => (
                <Tag
                    color={
                        chargeStatus === 2
                            ? 'red'
                            : chargeStatus === 3
                            ? 'volcano'
                            : chargeStatus === 1
                            ? 'green'
                            : 'orange'
                    }
                >
                    {getChargeStatus(chargeStatus).toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Cantidad',
            key: 'amount',
            dataIndex: 'amount',
            render(amount) {
                return <p>${formatMoney(amount)}</p>;
            },
        },
        // {
        //     title: 'Disponible',
        //     key: 'available',
        //     dataIndex: 'available',
        //     filters: [
        //         { text: 'Activa', value: true },
        //         { text: 'Desactivada', value: false },
        //     ],
        //     filteredValue: filteredInfo.available || null,
        //     onFilter: (value, record) => record.available === value,
        //     sorter: (a, b) => a.available - b.available,
        //     sortOrder: sortedInfo.columnKey === 'available' && sortedInfo.order,
        //     ellipsis: false,
        //     align: 'center',
        //     render: (statusText) => (
        //         <Tag color={statusText === false ? 'red' : 'green'}>
        //             {statusText ? <CheckOutlined /> : <CloseOutlined />}
        //         </Tag>
        //     ),
        // },
        {
            title: 'Fecha de creación del pago',
            key: 'creationDate',
            dataIndex: 'creationDate',
            render(creationDate) {
                return <p>{moment.unix(creationDate).format('LLL')}</p>;
            },
        },
        {
            title: 'Comprobante de pago',
            key: 'imageId',
            dataIndex: 'imageId',
            fixed: 'right',
            render: (imageId, key) =>
                imageId !== null ? (
                    <Tooltip title='Ver comprobante de pago'>
                        <Button
                            type='primary'
                            onClick={() => handleShowImage(key)}
                            icon={<EyeOutlined />}
                        />
                    </Tooltip>
                ) : (
                    <p>No Aplica</p>
                ),
        },
        {
            title: 'Acciones',
            key: 'key',
            fixed: 'right',
            width: 100,
            render: (key) => (
                <Tooltip title='Aprobar pago'>
                    <Button
                        type='primary'
                        onClick={() => handleConfirmPayment(key)}
                        icon={<CheckOutlined />}
                        className='approve-payment-btn'
                        disabled={
                            key.chargeStatus === 1 || key.chargeStatus === 3
                                ? true
                                : false
                        }
                    />
                </Tooltip>
            ),
        },
    ];

    const handleShowImage = (key) => {
        setModalTitle('Imágen del pago');
        setModalContent(
            <ShowImage setIsModalVisible={setIsModalVisible} item={key} />
        );
        setIsModalVisible(true);
    };

    const handleConfirmPayment = (key) => {
        confirm({
            title: 'Confirmación del pago',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estás seguro que deseas confirmar el pago?.',
            okText: 'Si',
            okType: 'primary',
            cancelText: 'No',
            onOk() {
                confirmPaymentAdmin(key, 1);
            },
            onCancel() {},
        });
    };

    const confirmPaymentAdmin = async (key, status) => {
        const accessToken = getAccessTokenApi();
        const data = {
            Status: status,
        };

        const result = await confirmPaymentAdminApi(accessToken, key.id, data);

        if (result) {
            if (
                result.statusCode === 404 ||
                result.statusCode === 409 ||
                result.statusCode === 400
            ) {
                notification['error']({
                    message: result.description,
                });
            } else if (result.statusCode === 200) {
                notification['success']({
                    message: 'Se modificó exitosamente el pago.',
                    description: 'Por favor actualice las fechas vigentes',
                });
                setReloadPromos(true);
                setModalTitle(
                    `Actualizar fechas de suscripción - ${key.companyId}`
                );
                setModalContent(
                    <SubscriptionRest
                        setIsVisibleModal={setIsModalVisible}
                        companyId={key.companyId}
                        disableSuscriptionType
                    />
                );
                setIsModalVisible(true);
            } else {
                notification['error']({
                    message: 'Ocurrió un error, intentelo mas tarde.',
                });
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }

        // setSelectedRowKeys([]);
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    // const hasSelected = selectedRowKeys.length > 0;

    function onChangeDate(date, dateString) {
        if (date) {
            setDateRecod({
                startDate: date[0].unix(),
                endDate: date[1].unix(),
            });
        }
    }

    return (
        <div className='table-promos'>
            <Row>
                <Col xs={24}>
                    <p
                        style={{
                            textAlign: 'right',
                            textTransform: 'capitalize',
                        }}
                    >
                        {moment().format('dddd LL')}
                    </p>
                </Col>
            </Row>
            <Row gutter={[10, 10]}>
                <Col xs={24} md={8}>
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format='DD-MM-YYYY HH:mm'
                        onChange={onChangeDate}
                    />
                </Col>
                <Col xs={24} md={8}>
                    <Button
                        danger
                        icon={<FilterOutlined />}
                        onClick={clearAll}
                        style={{ marginRight: 10 }}
                    >
                        Limpiar Filtros
                    </Button>
                </Col>
                <Col span={24} xs={24} md={8}>
                    <Paragraph>
                        Búsqueda del:{' '}
                        <Text strong>
                            {moment
                                .unix(dateRecord.startDate)
                                .format('DD-MM-YYYY HH:mm')}{' '}
                        </Text>
                        al{' '}
                        <Text strong>
                            {moment
                                .unix(dateRecord.endDate)
                                .format('DD-MM-YYYY HH:mm')}
                        </Text>
                    </Paragraph>
                </Col>
            </Row>
            {/* <div style={{ marginBottom: 16 }}>
                <span style={{ marginLeft: 8 }}>
                    {hasSelected
                        ? `Seleccionados ${selectedRowKeys.length} elemento(s)`
                        : ''}
                </span>
            </div> */}

            <Table
                bordered
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dataMenu}
                onChange={handleChange}
                loading={loadingTable}
                scroll={{ x: 950 }}
            />

            {/* <div style={{ marginBottom: 16 }}>
                <Button
                    type='primary'
                    danger
                    icon={<DeleteOutlined />}
                    size='large'
                    disabled={!hasSelected}
                    onClick={handleDeletePromo}
                >
                    {`Eliminar ${selectedRowKeys.length} ${
                        selectedRowKeys.length > 1 ? 'elementos' : 'elemento'
                    }`}
                </Button>
            </div> */}

            <Modal
                destroyOnClose
                title={modalTitle}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                footer={true}
            >
                {modalContent}
            </Modal>
        </div>
    );
};

export default TablePayments;

function ShowImage({ item }) {
    const { imageId, companyId, subscriptionTypeString, amount, creationDate } =
        item;
    const [imagePayment, setImagePayment] = useState(null);
    const [loadingImage, setLoadingImage] = useState(true);

    useEffect(() => {
        const token = getAccessTokenApi();
        getImagePaymentsApi(token, imageId)
            .then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        const { extension, imageData } = response.result;
                        setImagePayment(
                            `data:image/${extension};base64, ${imageData}`
                        );
                    } else {
                        let msgError = 'Ocurrió un error intentelo mas tarde.';
                        if (
                            response.statusCode === 404 ||
                            response.statusCode === 409 ||
                            response.statusCode === 400
                        ) {
                            msgError = response.description;
                        }
                        notification['error']({
                            message: msgError,
                        });
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                }
                setLoadingImage(false);
            })
            .catch((err) => console.log(err));
    }, [imageId]);

    return (
        <div className='show-image'>
            <Row>
                <Col xs={24} md={12}>
                    <Paragraph>
                        <Text strong>Compañia: </Text>
                        {companyId}
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Fecha: </Text>
                        {moment.unix(creationDate).format('LLL')}
                    </Paragraph>
                </Col>
                <Col xs={24} md={12}>
                    <Paragraph>
                        <Text strong>Paquete: </Text> {subscriptionTypeString}
                    </Paragraph>
                    <Paragraph>
                        <Text strong>Costo: </Text> ${formatMoney(amount)}
                    </Paragraph>
                </Col>
            </Row>
            <Divider>Comprobante</Divider>
            <div className='container-image'>
                {loadingImage && <Skeleton.Image />}
                {!loadingImage && imagePayment && (
                    <Image
                        width={200}
                        src={imagePayment}
                        // src='https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
                    />
                )}
                {!loadingImage && !imagePayment && (
                    <Paragraph strong type='danger'>
                        No se pudo cargar la imágen
                    </Paragraph>
                )}
            </div>
        </div>
    );
}
